import {
  InputContainer,
  Label,
  TextareaContainer,
  SelectContainer,
  CheckboxContainer,
} from "./styles";

const Input = ({
  type,
  placeholder,
  value,
  name,
  onChange,
  required,
  label,
  options,
}) => {
  return (
    <>
      <Label checkbox={type === "checkbox"} vertical={label.vertical}>
        {type !== "checkbox" && label.text}
        {type === "textarea" && (
          <TextareaContainer
            type={type}
            placeholder={placeholder}
            value={value}
            name={name}
            required={required}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
        {type === "text" && (
          <InputContainer
            type={type}
            placeholder={placeholder}
            value={value}
            name={name}
            required={required}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
        {type === "password" && (
          <InputContainer
            type={type}
            placeholder={placeholder}
            value={value}
            name={name}
            required={required}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
        {type === "email" && (
          <InputContainer
            type={type}
            placeholder={placeholder}
            value={value}
            name={name}
            required={required}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
        {type === "file" && (
          <InputContainer
            type={type}
            placeholder={placeholder}
            name={name}
            required={required}
            onChange={(e) => onChange(e.target.files[0])} // Acessa o arquivo corretamente
          />
        )}

        {type === "checkbox" && (
          <div
            style={{
              display: "flex",
              alignItems: "start",
              gap: 5,
              marginBottom: 16,
            }}
          >
            <CheckboxContainer
              type={type}
              name={name}
              required={required}
              onChange={(e) => onChange(e.target.value)} // Acessa o arquivo corretamente
            />

            <p>Em Breve</p>
          </div>
        )}
        {type === "select" && (
          <SelectContainer
            type={type}
            placeholder={placeholder}
            value={value}
            name={name}
            required={required}
            onChange={(e) => onChange(e.target.value)}
          >
            <option value="">Selecione</option>
            {options.map((opt, key) => (
              <option key={key} value={opt.value}>
                {opt.name}
              </option>
            ))}
          </SelectContainer>
        )}
      </Label>
    </>
  );
};

export default Input;

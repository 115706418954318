import styled, { createGlobalStyle } from "styled-components";
import { useState, useEffect } from "react";

// components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
//hooks
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";

//pages
import Home from "./pages/Home";
import Schedule from "./pages/Schedule";
import Schools from "./pages/Schools";
import Courses from "./pages/Courses";
import AnimationParty from "./pages/AnimationParty";
import AdminConfig from "./pages/AdminConfig";
import Login from "./pages/Login";
import { Navigate } from "react-router-dom";

//CONTEXT
import { useAuthentication } from "./hooks/useAuthentication";
import { onAuthStateChanged } from "firebase/auth";
import AdminSchedule from "./pages/AdminSchedule";
import AdminSchool from "./pages/AdminSchool";
import AdminCourse from "./pages/AdminCourse";
import AdminAnimation from "./pages/AdminAnimation";
import Sidebar from "./components/Sidebar";
import AdminAddTheater from "./pages/AdminAddTheater";
import AdminEditTheater from "./pages/AdminEditTheater";
import AdminTheater from "./pages/AdminTheater";
import Loading from "./components/Loading";

const desktop = 920;

const GlobalStyle = createGlobalStyle`
  body{
    font-family: "League Spartan", sans-serif;
  }
  
  *{
    margin: 0;
    padding: 0;
  }

  label,p,h1,h2,h3,h4,h5,input{
    color:#5c5c5c ;
  }

  ::placeholder {
  color: #909090;
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
}

`;

export const TitleSectionsPatern = styled.h2`
  background-color: #010080;
  width: 100%;
  text-align: center;
  padding: 0.3em 0 0.11em;
  text-transform: uppercase;
  color: #fffe00;
  font-size: 2em;
  word-wrap: break-word;

  @media (min-width: ${desktop}px) {
    width: 70%;
    margin: 0 auto;
  }
`;

export const ParagraphPatern = styled.p`
  margin: 20px auto;
  width: 100%;
  text-align: justify;
  color: #4c4c4c;
  font-size: 20px;
  line-height: 1.8em;
`;

export const Div = styled.div`
  ${(props) => props.user && "display: flex;gap: 1rem;"}
`;

export default function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Content />
    </BrowserRouter>
  );
}

function Content() {
  const [user, setUser] = useState(undefined);
  const { auth } = useAuthentication();
  const loadingUser = user === undefined;
  const location = useLocation();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  }, [auth]);

  if (loadingUser) {
    return <Loading />;
  }

  return (
    <Div
      user={
        location.pathname.split("-")[0] === "/admin" &&
        location.pathname !== "/admin-login"
      }
    >
      {location.pathname.split("-")[0] !== "/admin" && <Navbar />}
      {location.pathname.split("-")[0] === "/admin" && user && <Sidebar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/schools" element={<Schools />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/animation-party" element={<AnimationParty />} />
        <Route
          path="/admin-login"
          element={!user ? <Login /> : <Navigate to="/admin-schedule" />}
        />
        <Route
          path="/admin-schedule"
          element={user ? <AdminSchedule /> : <Navigate to="/" />}
        />
        <Route
          path="/admin-add-theater"
          element={user ? <AdminAddTheater /> : <Navigate to="/" />}
        />
        <Route
          path="/admin-school"
          element={user ? <AdminSchool /> : <Navigate to="/" />}
        />
        <Route
          path="/admin-course"
          element={user ? <AdminCourse /> : <Navigate to="/" />}
        />
        <Route
          path="/admin-animation"
          element={user ? <AdminAnimation /> : <Navigate to="/" />}
        />
        <Route
          path="/admin-theater"
          element={user ? <AdminTheater /> : <Navigate to="/" />}
        />
        <Route
          path="/admin-edit-theater"
          element={user ? <AdminEditTheater /> : <Navigate to="/" />}
        />
        <Route
          path="/admin-config"
          element={user ? <AdminConfig /> : <Navigate to="/" />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {location.pathname.split("-")[0] !== "/admin" && <Footer />}
    </Div>
  );
}

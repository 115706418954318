import styled from "styled-components";

const desktop = 920;

export const Paragraph = styled.p`
  margin: 20px auto;
  width: 100%;
  text-align: justify;
  color: #4c4c4c;
  font-size: 15px;
  line-height: 1.8em;
  ${(props) =>
    props.limited
      ? "white-space: nowrap;overflow: hidden; text-overflow: ellipsis;"
      : ""}
`;

export const Right = styled.div`
  img {
    width: 100%;
    border-radius: 10px;
  }
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Left = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Center = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const InTheatersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #8c8c8c;
  width: 95%;
  margin: 3rem auto 1rem;
  background-color: white;
  border-radius: 20px;
  row-gap: 1.5em;
  transition: transform 0.5s;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
  }

  p {
    margin: 0;
  }

  h3 {
    margin-top: 0.5rem;
    font-size: 20px;
    text-transform: uppercase;
    color: #010080;
    text-align: center;
  }

  @media (min-width: ${desktop}px) {
    max-width: 1000px;
  }
`;

export const Button = styled.a`
  cursor: pointer;
  text-decoration: none;
  background-color: ${(props) =>
    props.type === "primary" ? "#302fb5" : "white"};
  opacity: 0.8;
  color: ${(props) => (props.type === "primary" ? "white" : "#302fb5")};
  transition: opacity 0.4s;
  border: ${(props) =>
    props.type === "primary" ? "none" : "1px solid #302fb5"};
  padding: 0.5em 1.5em;
  border-radius: 2px;
  transition: background-color 300ms;
  &:hover {
    background-color: #010080;
    color: white;
  }
`;

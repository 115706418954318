import PresetContainer from "../../components/PresetContainer";

const AdminAnimation = () => {
  return (
    <PresetContainer>
      <h1 style={{ marginBottom: 10 }}>Animações</h1>
      <h2>Em Breve!</h2>
    </PresetContainer>
  );
};

export default AdminAnimation;

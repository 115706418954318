import { LoadingContainer, Spinner } from "./styles";

const Loading = () => {
  return (
    <LoadingContainer>
      <Spinner></Spinner>
    </LoadingContainer>
  );
};

export default Loading;

import Swal from "sweetalert2";

export const showMessage = (type, message, handleClose) => {
  Swal.fire({
    position: "top-end",
    icon: type,
    toast: true,
    timerProgressBar: true,
    title: message,
    showConfirmButton: false,
    timer: 2000,
    didClose: handleClose
      ? () => {
          handleClose();
        }
      : null,
  });
};

import React from 'react'
import { Container, Error } from './styles'

const ErrorMessage = ({ content }) => {
    return (
        <Container>
            <Error>
                {content}
            </Error>
        </Container>
    )
}

export default ErrorMessage
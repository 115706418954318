import React from "react";
import iconLogo from "../../images/favicon.png";
import {
  SidebarContainer,
  Header,
  Menu,
  Img,
  SidebarSpace,
  Button,
} from "./styles";
import { useAuthentication } from "../../hooks/useAuthentication";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const { logout } = useAuthentication();
  const location = useLocation();

  return (
    <>
      <SidebarSpace></SidebarSpace>
      <SidebarContainer>
        <Header>
          <Img src={iconLogo} alt="Logo Misenscene" />
          <h1>Administração</h1>
        </Header>
        <Menu>
          <ul>
            <li>
              <Button
                isActive={location.pathname === "/admin-add-theater"}
                onClick={() => navigate("/admin-add-theater")}
              >
                Adicionar Peça
              </Button>
            </li>
            <li>
              <Button
                isActive={location.pathname === "/admin-schedule"}
                onClick={() => navigate("/admin-schedule")}
              >
                Programação
              </Button>
            </li>
            <li>
              <Button
                isActive={location.pathname === "/admin-school"}
                onClick={() => navigate("/admin-school")}
              >
                Escolas
              </Button>
            </li>
            <li>
              <Button
                isActive={location.pathname === "/admin-course"}
                onClick={() => navigate("/admin-course")}
              >
                Cursos
              </Button>
            </li>
            <li>
              <Button
                isActive={location.pathname === "/admin-animation"}
                onClick={() => navigate("/admin-animation")}
              >
                Animações
              </Button>
            </li>
            <li>
              <Button
                isActive={location.pathname === "/admin-config"}
                onClick={() => navigate("/admin-config")}
              >
                Configurações
              </Button>
            </li>
            <li>
              <Button onClick={() => logout()}>Sair</Button>
            </li>
          </ul>
        </Menu>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;

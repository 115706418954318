import { useState, useEffect, useReducer } from "react";
import { db, storage } from "../firebase/config";
import { updateDoc, doc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const initialState = {
  loading: null,
  error: null,
};

const updateReducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { loading: true, error: null };
    case "ERROR":
      return { loading: false, error: action.payload };
    case "UPDATED_DOC":
      return { loading: false, error: null };
    default:
      return state;
  }
};

export const useUpdateDocument = (docCollection) => {
  const [response, dispatch] = useReducer(updateReducer, initialState);

  // deal with memory leak
  const [cancelled, setCancelled] = useState(false);

  const checkCancelBeforeDispatch = (action) => {
    if (cancelled) {
      dispatch(action);
    }
  };

  const updateDocument = async (id, data, file) => {
    checkCancelBeforeDispatch({
      type: "LOADING",
    });
    try {
      const docRef = await doc(db, docCollection, id);
      console.log(file);
      console.log(typeof file);
      let fileUrl = null;
      if (file && typeof file !== "string") {
        const fileRef = ref(storage, `uploads/${file.name}`);
        await uploadBytes(fileRef, file);
        fileUrl = await getDownloadURL(fileRef);
      }

      const updatedData = fileUrl ? { ...data, imagem: fileUrl } : data;

      await updateDoc(docRef, updatedData);

      checkCancelBeforeDispatch({
        type: "UPDATED_DOC",
        payload: updateDocument,
      });
    } catch (error) {
      checkCancelBeforeDispatch({
        type: "ERROR",
        payload: error.message,
      });
    }
  };

  useEffect(() => {
    return () => setCancelled(true);
  }, []);

  return { updateDocument, response };
};

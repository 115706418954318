//css
import { ScheduleContainer, TheatersSection, City } from "./styles";
import { TitleSectionsPatern } from "../../App";

//components
import InTheaters from "../../components/InTheaters";

//hooks
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Contacts from "../../components/Contacts";
import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import Loading from "../../components/Loading";

const Schedule = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { documents, loading, error } = useFetchDocuments("teatros", "1");

  return (
    <ScheduleContainer>
      <TheatersSection>
        <TitleSectionsPatern>Em cartaz</TitleSectionsPatern>
        {loading && <Loading />}
        {!loading && (
          <>
            <City>São Lourenço do Sul</City>
            {documents && !loading && !error && (
              <>
                {documents &&
                  documents.filter(
                    (doc) => doc.cidade === "São Lourenço do Sul"
                  ).length <= 0 && <h2>Em Breve!</h2>}
                {documents &&
                  documents.length > 0 &&
                  documents.filter(
                    (doc) => doc.cidade === "São Lourenço do Sul"
                  ).length > 0 &&
                  documents
                    .filter((doc) => doc.cidade === "São Lourenço do Sul")
                    .map((peca, i) => <InTheaters key={i} theater={peca} />)}
              </>
            )}
            <City>Porto Alegre</City>
            {documents && !loading && !error && (
              <>
                {documents &&
                  documents.filter((doc) => doc.cidade === "Porto Alegre")
                    .length <= 0 && <h2>Em Breve!</h2>}
                {documents &&
                  documents.length > 0 &&
                  documents.filter((doc) => doc.cidade === "Porto Alegre")
                    .length > 0 &&
                  documents
                    .filter((doc) => doc.cidade === "Porto Alegre")
                    .map((peca, i) => <InTheaters key={i} theater={peca} />)}
              </>
            )}
            <City>Cachoeirinha</City>
            {documents && !loading && !error && (
              <>
                {documents &&
                  documents.filter((doc) => doc.cidade === "Cachoeirinha")
                    .length <= 0 && <h2>Em Breve!</h2>}
                {documents &&
                  documents.length > 0 &&
                  documents.filter((doc) => doc.cidade === "Cachoeirinha")
                    .length > 0 &&
                  documents
                    .filter((doc) => doc.cidade === "Cachoeirinha")
                    .map((peca, i) => <InTheaters key={i} theater={peca} />)}
              </>
            )}
          </>
        )}
      </TheatersSection>
      <Contacts subject={"Programação"} />
    </ScheduleContainer>
  );
};

export default Schedule;

import PresetContainer from "../../components/PresetContainer";
import { Container } from "./styles";
import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import AdminInTheaters from "../../components/AdminInTheaters";
import Loading from "../../components/Loading";

const AdminSchedule = () => {
  const { documents, loading, error } = useFetchDocuments("teatros", "1");
  return (
    <PresetContainer>
      {loading && (
        <>
          <Loading />
        </>
      )}
      {documents && !loading && !error && (
        <>
          <Container>
            {documents &&
              documents.length > 0 &&
              documents.map((peca, i) => (
                <AdminInTheaters key={i} theater={peca} />
              ))}
          </Container>
        </>
      )}
    </PresetContainer>
  );
};

export default AdminSchedule;

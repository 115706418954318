//css
import { useNavigate } from "react-router-dom";
import {
  InTheatersContainer,
  Right,
  Left,
  Center,
  Button,
  Paragraph,
} from "./styles";

const AdminInTheaters = ({ theater }) => {
  const {
    titulo,
    imagem,
    subtitulo,
    sinopse,
    data,
    horario,
    local,
    elenco,
    producao,
    infoAdicional,
    textoBotao,
    linkBotao,
    direcao,
    classificacao,
    cidade,
  } = theater;

  const navigate = useNavigate();
  return (
    <>
      <InTheatersContainer
        onClick={() =>
          navigate("/admin-theater", { state: { id: theater.id } })
        }
      >
        <Right>
          <img src={imagem} alt={titulo} />
          <h3>{titulo}</h3>
          {subtitulo && (
            <Paragraph>
              <strong>Subtitulo:</strong> {subtitulo}
            </Paragraph>
          )}
        </Right>
        <Center>
          {sinopse && (
            <Paragraph limited={true}>
              <strong>Sinopse:</strong> {sinopse}
            </Paragraph>
          )}
          {data && (
            <Paragraph>
              <strong>Data:</strong> {data}
            </Paragraph>
          )}
          {elenco && (
            <Paragraph limited={true}>
              <strong>Elenco:</strong> {elenco}
            </Paragraph>
          )}
          {producao && (
            <Paragraph>
              <strong>Produção:</strong> {producao}
            </Paragraph>
          )}
          {classificacao && (
            <Paragraph>
              <strong>Classificação Indicativa:</strong> {classificacao}
            </Paragraph>
          )}
        </Center>
        <Left>
          {local && (
            <Paragraph>
              <strong>Local:</strong> {local}
            </Paragraph>
          )}
          {direcao && (
            <Paragraph>
              <strong>Direção e Roteiro:</strong> {direcao}
            </Paragraph>
          )}
          {cidade && (
            <Paragraph>
              <strong>Cidade:</strong> {cidade}
            </Paragraph>
          )}
          {infoAdicional && (
            <Paragraph>
              <strong>Informações Adicionais:</strong> {infoAdicional}
            </Paragraph>
          )}
          {horario && (
            <Paragraph>
              <strong>Horário(s):</strong> {horario}
            </Paragraph>
          )}
          {linkBotao && textoBotao && (
            <Button
              type="primary"
              href={linkBotao}
              target="_blank"
              rel="noreferrer"
            >
              {textoBotao}
            </Button>
          )}
        </Left>
      </InTheatersContainer>
    </>
  );
};

export default AdminInTheaters;

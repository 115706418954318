//css
import { ParagraphPatern } from "../../App";
import { InTheatersContainer } from "./styles";

const InTheaters = ({ theater }) => {
  const {
    titulo,
    imagem,
    subtitulo,
    sinopse,
    data,
    horario,
    local,
    elenco,
    producao,
    infoAdicional,
    textoBotao,
    linkBotao,
    direcao,
    classificacao,
  } = theater;
  return (
    <InTheatersContainer>
      <img src={imagem} alt={titulo} />
      <h3>{titulo}</h3>
      {subtitulo && <ParagraphPatern>{subtitulo}</ParagraphPatern>}
      {sinopse && <ParagraphPatern>{sinopse}</ParagraphPatern>}
      {data && <ParagraphPatern>{data}</ParagraphPatern>}
      {horario && <ParagraphPatern>Horário(s): {horario}</ParagraphPatern>}
      {local && <ParagraphPatern>Local: {local}</ParagraphPatern>}
      {direcao && (
        <ParagraphPatern>Direção e Roteiro: {direcao}</ParagraphPatern>
      )}
      {elenco && <ParagraphPatern>Elenco: {elenco}</ParagraphPatern>}
      {producao && <ParagraphPatern>Produção: {producao}</ParagraphPatern>}
      {infoAdicional && <ParagraphPatern>{infoAdicional}</ParagraphPatern>}
      {classificacao && (
        <ParagraphPatern>
          Classificação Indicativa: {classificacao}
        </ParagraphPatern>
      )}
      {linkBotao && textoBotao && (
        <a href={linkBotao} target="_blank" rel="noreferrer">
          {textoBotao}
        </a>
      )}
      <span></span>
    </InTheatersContainer>
  );
};

export default InTheaters;

import styled from "styled-components";

export const InputContainer = styled.input`
  border: none;
  outline: none;
  height: 2rem;
  width: 100%;
  background-color: transparent;
  font-weight: bold;
`;
export const TextareaContainer = styled.textarea`
  border: none;
  outline: none;
  height: 5rem !important;
  width: 100% !important;
  resize: none;
  background-color: transparent;
`;
export const SelectContainer = styled.select`
  border: none;
  outline: none;
  height: 2rem;
  width: 300px;
  background-color: transparent;
`;

export const CheckboxContainer = styled.input`
  border: none;
  outline: none;
  background-color: transparent;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  border-bottom: ${(props) =>
    !props.checkbox ? " 1px solid black" : "1px solid transparent"};
  justify-content: center;
  gap: 0.5rem;
  font-weight: bold;
  align-items: ${(props) => (props.vertical ? "start" : "center")};
`;

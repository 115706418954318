import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

import { useState, useEffect } from "react";
export const useAuthentication = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  //cleanup
  //deal with memory liquid
  const [cancelled, setCancelled] = useState(false);

  const auth = getAuth();

  function checkIfIsCancelled() {
    if (cancelled) {
      return;
    }
  }

  //logout
  const logout = () => {
    checkIfIsCancelled();
    setLoading(true);

    signOut(auth);
  };

  //Sign in
  const signIn = async (data) => {
    checkIfIsCancelled();
    setLoading(true);
    setError(false);

    try {
      await signInWithEmailAndPassword(auth, data.loginEmail, data.password);
      setLoading(false);
    } catch (error) {
      let systemErrorMessage = {
        date: Date.now(),
        msg: "",
      };

      if (error.message.includes("invalid-credential")) {
        systemErrorMessage.msg = "Suas credenciais estão incorretas";
      }

      setLoading(false);

      setError(systemErrorMessage);
    }
  };

  useEffect(() => {
    return () => setCancelled(true);
  }, []);

  return {
    auth,
    error,
    loading,
    logout,
    signIn,
  };
};

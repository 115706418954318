/* import Loading from "../Loading"; */
import { ButtonContainer, Spinner } from "./styles";

const Button = ({ title, handleClick, loading, type, disabled }) => {
  return (
    <ButtonContainer
      disabled={disabled}
      type={type}
      onClick={(e) => handleClick(e)}
    >
      {loading ? <Spinner /> : title}
    </ButtonContainer>
  );
};

export default Button;

import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const ButtonContainer = styled.button`
  cursor: pointer;
  background-color: ${(props) => {
    switch (props.type) {
      case "primary":
        return "#302fb5";
      case "secundary":
        return "white";
      case "warning":
        return "#ff4f4f";
      default:
        return "#302fb5";
    }
  }};
  opacity: 0.8;
  color: ${(props) => {
    switch (props.type) {
      case "primary":
        return "white";
      case "secundary":
        return "#302fb5";
      case "warning":
        return "white";
      default:
        return "#302fb5";
    }
  }};
  transition: opacity 0.4s;
  border: ${(props) => {
    switch (props.type) {
      case "primary":
        return "1px solid transparent";
      case "secundary":
        return "1px solid #302fb5";
      case "warning":
        return "1px solid transparent";
      default:
        return "1px solid transparent";
    }
  }};
  padding: 0.5em 1.5em;
  border-radius: 2px;
  transition: background-color 300ms;
  &:hover {
    background-color: ${(props) => {
      switch (props.type) {
        case "primary":
          return "#010080";
        case "secundary":
          return "#302fb5";
        case "warning":
          return "#d33131";
        default:
          return "#010080";
      }
    }};
    color: white;
  }
`;

export const Spinner = styled.div`
  margin: 0 auto;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: ${spin} 1s linear infinite;
`;

/* import React, { useState } from "react"; */
import PresetContainer from "../../components/PresetContainer";
/* import {
  ParamContainer,
  ParamRow,
  Input,
  RemoveButton,
  AddButton,
} from "./styles"; */

const AdminConfig = () => {
  /*   const [params, setParams] = useState({
    cidades: [],
    descricaoCurso: "",
    descricaoTeatro: "",
    descricaoAnimacaoFesta: "",
    atividadesAnimacaoFesta: [],
    email: "",
    instagram: "",
    whatsapp: "",
  }); */
  /* 
  const handleAdd = (param) => {
    setParams((prevParams) => ({
      ...prevParams,
      [param]: [...prevParams[param], ""],
    }));
  }; */
  /* 
  const handleRemove = (param, index) => {
    setParams((prevParams) => ({
      ...prevParams,
      [param]: prevParams[param].filter((_, i) => i !== index),
    }));
  }; */

  /*   const handleChange = (param, index, value) => {
    setParams((prevParams) => {
      const newValues = [...prevParams[param]];
      newValues[index] = value;
      return {
        ...prevParams,
        [param]: newValues,
      };
    });
  }; */

  return (
    <PresetContainer>
      <h1 style={{ marginBottom: 10 }}>Configurações</h1>
      {/* {Object.keys(params).map((param) => (
        <ParamContainer key={param}>
          <h2>{param.replace(/([A-Z])/g, " $1")}</h2>
          {Array.isArray(params[param]) ? (
            <>
              {params[param].map((value, index) => (
                <ParamRow key={index}>
                  <Input
                    type="text"
                    value={value}
                    onChange={(e) => handleChange(param, index, e.target.value)}
                  />
                  <RemoveButton onClick={() => handleRemove(param, index)}>
                    Remover
                  </RemoveButton>
                </ParamRow>
              ))}
              <AddButton onClick={() => handleAdd(param)}>Adicionar</AddButton>
            </>
          ) : (
            <Input
              type="text"
              value={params[param]}
              onChange={(e) => handleChange(param, 0, e.target.value)}
            />
          )}
        </ParamContainer>
      ))} */}
      <h2>Em Breve!</h2>
    </PresetContainer>
  );
};

export default AdminConfig;

import styled from "styled-components";

export const SidebarContainer = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: white;
  left: 0;
  width: 250px;
  display: flex;
  flex-direction: column;
  box-shadow: 10px 0 15px -5px rgba(0, 0, 0, 0.3);
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
`;

export const Menu = styled.div`
  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;
export const Img = styled.img`
  width: 100px;
`;

export const SidebarSpace = styled.div`
  min-width: 250px;
  max-width: 250px;
  height: 100vh;
  margin: 0;
  padding: 0;
`;

export const Button = styled.button`
  font-weight: medium;
  font-size: 20px;
  display: block;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: ${(props) =>
    props.isActive ? "2px solid black" : "1px solid black"};
  margin-left: 2rem;
  cursor: pointer;
  transition: background-color 0.5s;
  &:hover {
    color: #aa0000;
    border-bottom: ${(props) =>
      props.isActive ? "3px solid #aa0000" : "1px solid #aa0000"};
  }
`;

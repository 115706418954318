import { LoginContainer, Form, Title, Img } from "./styles";
import { useState, useEffect } from "react";
import { useAuthentication } from "../../hooks/useAuthentication";
import Button from "../../components/Button";
import Input from "../../components/Input";
import iconLogo from "../../images/favicon.png";
import ErrorMessage from "../../components/ErrorMessage";

const Login = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loading, signIn, error: authError } = useAuthentication();
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");

    const user = {
      loginEmail,
      password,
    };

    await signIn(user);
  };

  useEffect(() => {
    if (authError) {
      setError(authError.msg);
    }
  }, [authError]);

  return (
    <LoginContainer>
      <Title>Login Admin</Title>
      <Img src={iconLogo} alt={"Logo Misenscene"} />
      <Form onSubmit={handleSubmit}>
        <Input
          type="email"
          name="loginEmail"
          required={true}
          value={loginEmail}
          placeholder="Digite seu email"
          onChange={setLoginEmail}
          label={{
            text: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                style={{ width: "1.5rem" }}
              >
                <path
                  fillRule="evenodd"
                  d="M7.5 5.25a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0 1 12 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 0 1 7.5 5.455V5.25Zm7.5 0v.09a49.488 49.488 0 0 0-6 0v-.09a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5Zm-3 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  clipRule="evenodd"
                />
                <path d="M3 18.4v-2.796a4.3 4.3 0 0 0 .713.31A26.226 26.226 0 0 0 12 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 0 1-6.477-.427C4.047 21.128 3 19.852 3 18.4Z" />
              </svg>
            ),
            vertical: false,
          }}
        />

        <Input
          type="password"
          name="loginSenha"
          required={true}
          value={password}
          placeholder="Digite seu senha"
          onChange={setPassword}
          label={{
            text: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                style={{ width: "1.5rem" }}
              >
                <path
                  fillRule="evenodd"
                  d="M12 3.75a6.715 6.715 0 0 0-3.722 1.118.75.75 0 1 1-.828-1.25 8.25 8.25 0 0 1 12.8 6.883c0 3.014-.574 5.897-1.62 8.543a.75.75 0 0 1-1.395-.551A21.69 21.69 0 0 0 18.75 10.5 6.75 6.75 0 0 0 12 3.75ZM6.157 5.739a.75.75 0 0 1 .21 1.04A6.715 6.715 0 0 0 5.25 10.5c0 1.613-.463 3.12-1.265 4.393a.75.75 0 0 1-1.27-.8A6.715 6.715 0 0 0 3.75 10.5c0-1.68.503-3.246 1.367-4.55a.75.75 0 0 1 1.04-.211ZM12 7.5a3 3 0 0 0-3 3c0 3.1-1.176 5.927-3.105 8.056a.75.75 0 1 1-1.112-1.008A10.459 10.459 0 0 0 7.5 10.5a4.5 4.5 0 1 1 9 0c0 .547-.022 1.09-.067 1.626a.75.75 0 0 1-1.495-.123c.041-.495.062-.996.062-1.503a3 3 0 0 0-3-3Zm0 2.25a.75.75 0 0 1 .75.75c0 3.908-1.424 7.485-3.781 10.238a.75.75 0 0 1-1.14-.975A14.19 14.19 0 0 0 11.25 10.5a.75.75 0 0 1 .75-.75Zm3.239 5.183a.75.75 0 0 1 .515.927 19.417 19.417 0 0 1-2.585 5.544.75.75 0 0 1-1.243-.84 17.915 17.915 0 0 0 2.386-5.116.75.75 0 0 1 .927-.515Z"
                  clipRule="evenodd"
                />
              </svg>
            ),
            vertical: false,
          }}
        />
        {error && <ErrorMessage content={error} />}
        <Button
          type="primary"
          title={"Login"}
          loading={loading}
          handleClick={(e) => handleSubmit(e)}
        ></Button>
      </Form>
    </LoginContainer>
  );
};

export default Login;

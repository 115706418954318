/* import PresetContainer from "../../components/PresetContainer";

const AdminCourse = () => {
  return (
    <PresetContainer>
      <h1 style={{ marginBottom: 10 }}>Cursos</h1>
      <h2>Em Breve!</h2>
    </PresetContainer>
  );
};

export default AdminCourse;
 */

import PresetContainer from "../../components/PresetContainer";
import { Container } from "./styles";
import { useFetchDocuments } from "../../hooks/useFetchDocuments";
import AdminInTheaters from "../../components/AdminInTheaters";
import Loading from "../../components/Loading";

const AdminCourse = () => {
  const { documents, loading, error } = useFetchDocuments("teatros", "3");
  return (
    <PresetContainer>
      {loading && (
        <>
          <Loading />
        </>
      )}
      {documents && !loading && !error && (
        <>
          <Container>
            {documents &&
              documents.length > 0 &&
              documents.map((peca, i) => (
                <AdminInTheaters key={i} theater={peca} />
              ))}
          </Container>
        </>
      )}
    </PresetContainer>
  );
};

export default AdminCourse;
